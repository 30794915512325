const getters = {
  device: state => state.app.device,
  userInfo: state => state.app.userInfo,
  dialogLogin: state => state.app.dialogLogin,
  shoppingCart: state => state.app.shoppingCart,
  isbindspcode:state => state.app.isbindspcode,
  currentSubject: state => state.app.currentSubject,
  currentNavigation: state => state.app.currentNavigation,
  navigations: state => state.app.navigations,
  resources: state => state.app.resources,
  currentCourseId: state => state.app.currentCourseId,
  purchasedCourses: state => state.app.purchasedCourses,
  showCourseDetail: state => state.app.showCourseDetail,
  currentVideoUrl: state => state.app.currentVideoUrl,
  modifyPasswordDialogVisible: state => state.app.modifyPasswordDialogVisible,
}
export default getters