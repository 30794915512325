import Config from '@/config/config.js'

const state = {
  device: 'pc', // 默认是PC端，====》 PC端：pc、移动端：m
  userInfo: {},
  dialogLogin: false, // 登录弹窗
  shoppingCart: [], // 购物车
  isBindWx: false,
  isbindspcode: false, //是否为团购
  currentSubject: {}, // 当前学科
  currentNavigation: {}, // 当前导航
  navigations: [], // 导航
  resources: [], // 资源
  currentCourseId: 0, // 当前课程id
  purchasedCourses:[],//已购买课程
  showCourseDetail: true,//是否显示视频详情
  currentVideoUrl: '',//当前视频地址
  modifyPasswordDialogVisible: false, // 是否显示修改密码弹窗
  loginVerifyCode:"243556",//登录短信验证码
}

const mutations = {
  SE_BINDSPCODE: (state, bool) => {
    state.isbindspcode = bool
  },
  SET_DEVICE: (state, device) => {
    state.device = device
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_DIALOGLOGIN: (state, dialogLogin) => {
    state.dialogLogin = dialogLogin
  },
  SET_SHOPPING_CART: (state, cart) => {
    state.shoppingCart = cart
  },
  SET_IS_BIND_WX: (state, bind) => {
    state.isBindWx = bind
  },
  SET_CURRENT_SUBJECT: (state, courseId) => {
    const subjects = [
      { id: 1, label: '语文', navi: "yw", imgSrc: "public/img/yw.jpg", subjectId: 89866 },
      { id: 2, label: '数学', navi: "sx", imgSrc: "public/img/sx.jpg", subjectId: 89867 },
      { id: 3, label: '英语', navi: "yy", imgSrc: "public/img/yy.jpg", subjectId: 89868 },
      { id: 4, label: '物理', navi: "wl", imgSrc: "public/img/wl.jpg", subjectId: 89872 },
      { id: 5, label: '化学', navi: "hx", imgSrc: "public/img/hx.jpg", subjectId: 89873 },
      { id: 6, label: '生物', navi: "sw", imgSrc: "public/img/sw.jpg", subjectId: 89874 },
      { id: 7, label: '政治', navi: "zz", imgSrc: "public/img/zz.jpg", subjectId: 89869 },
      { id: 8, label: '历史', navi: "ls", imgSrc: "public/img/ls.jpg", subjectId: 89870 },
      { id: 9, label: '地理', navi: "dl", imgSrc: "public/img/dl.jpg", subjectId: 89871 },
    ]
   
    let currentSubject = subjects.find(item => item.subjectId == courseId)
    state.currentSubject = currentSubject
  },
  SET_CURRENT_NAVIGATION: (state, currentNavigation) => {
    state.currentNavigation = currentNavigation
  },
  SET_NAVIGATIONS: (state, subject) => {
    let ns = [Config.yw, Config.sx, Config.yy, Config.wl, Config.hx, Config.sw, Config.zz, Config.ls, Config.dl]

    let navigations=ns.find(item => item[0].course_id == subject.courseId)
    state.navigations = navigations
  },
  SET_RESOURCES: (state, resources) => {
    state.resources = resources
  },
  SET_CURRENT_COURSE_ID: (state, currentCourseId) => {
    state.currentCourseId = currentCourseId
  },
  SET_PURCHASED_COURSES: (state, purchasedCourses) => {
    state.purchasedCourses = purchasedCourses
  },
  SET_SHOW_COURSE_DETAIL: (state, showCourseDetail) => {
    state.showCourseDetail = showCourseDetail
  },
  SET_CURRENT_VIDEO_URL: (state, currentVideoUrl) => {
    state.currentVideoUrl = currentVideoUrl
  },
  SET_MODIFY_PASSWORD_DIALOG(state, dialogVisible) {
    state.modifyPasswordDialogVisible = dialogVisible
  },
  SET_LOGIN_VERIFY_CODE(state, loginVerifyCode) {
    state.loginVerifyCode = loginVerifyCode
  }
  
}

const actions = {
  setDevice({ commit }, device) {
    commit('SET_DEVICE', device)
  },
  setUserInfo({ commit }, userInfo) {
    commit('SET_USERINFO', userInfo)
  },
  setDialogLogin({ commit }, dialogLogin) {
    commit('SET_DIALOGLOGIN', dialogLogin)
  },
  setShoppingCart({ commit }, cart) {
    commit('SET_SHOPPING_CART', cart)
  },
  setIsBindWx({ commit }, bind) {
    commit('SET_IS_BIND_WX', bind)
  },
  setLoginDialog({ commit }, dialogLogin) {
    commit('SET_DIALOGLOGIN', dialogLogin)
  },
  setCurrentSubject({ commit }, item) {
    console.log(item, 'item');
    commit('SET_CURRENT_SUBJECT', item.courseId)
  },
  setCurrentNavigation({ commit }, item) {
    commit('SET_CURRENT_NAVIGATION', item)
  },
  setNavigations({ commit }, item) {
    commit('SET_NAVIGATIONS', item)
  },
  setResources({ commit }, item) {
    commit('SET_RESOURCES', item)
  },
  setCurrentCourseId({ commit }, item) {
    commit('SET_CURRENT_COURSE_ID', item)
  },
  setPurchasedCourses({ commit }, item) {
    commit('SET_PURCHASED_COURSES', item)
  },
  setShowCourseDetail({ commit }, item) {
    commit('SET_SHOW_COURSE_DETAIL', item)
  },
  setCurrentVideoUrl({ commit }, item) {
    commit('SET_CURRENT_VIDEO_URL', item)
  },
  setModifyPasswordDialogVisible({ commit }, item) {
    commit('SET_MODIFY_PASSWORD_DIALOG', item)
  },
  setLoginVerifyCode({ commit }, item) {
    commit('SET_LOGIN_VERIFY_CODE', item)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}