import Vue from "vue"
import Router from "vue-router"
import { Message } from "element-ui"
import store from "@/store"
Vue.use(Router)
const router = new Router({
  routes: [
    {
      path: "/coursefree",
      name: "coursefree",
      component: () => import("@/views/index/coursefree"),
      meta: {
        title: 'AI教我学《高考专家备考锦囊》带你高考致胜--学智通慧'
      }
    },
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/index"),
      meta: {
        title: 'AI教我学《高考专家备考锦囊》带你高考致胜--学智通慧'      }
    },
    {
      path: "/detail",
      name: "Detail",
      component: () => import("@/views/index/detail"),
      meta: {
        title: "详情"
      }
    },
    {
      path: "/lesson",
      name: "Lesson",
      component: () => import("@/views/index/detail"),
      meta: {
        title: "详情"
      }
    },
    {
      path: "/pay",
      name: "pay",
      component: () => import("@/views/index/m/selectpayway"),
      meta: {
        title: "支付"
      }
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/components/m/Login"),
      meta: {
        title: "登录"
      }
    },
    {
      path: "/loginstatus",
      name: "loginstatus",
      component: () => import("@/views/index/loginstatus"),
      meta: {
        title: "登录成功"
      }
    },
    {
      path: "/wxcodepay",
      name: "wxcodepay",
      component: () => import("@/views/index/m/wxcodepay"),
      meta: {
        title: "微信支付"
      }
    },
    {
      path: "/shopping",
      name: "Shopping",
      component: () => import("@/views/index/shopping"),
      meta: {
        title: "购物车"
      }
    },
    {
      path: "/shoppings",
      name: "Shoppings",
      component: () => import("@/views/index/shoppings"),
      meta: {
        title: "购物车"
      }
    },
    {
      path: "/peoplecenter",
      name: "peoplecenter",
      component: () => import("@/views/index/m/people-center"),
      meta: {
        title: "我的课"
      }
    },
    {
      path: "/layout",
      name: "PcLayout",
      component: () => import("@/views/index/pclayout"),
      children: [
        {
          path: "personal",
          name: "Personal",
          meta: {
            title: "我的课"
          },
          component: () => import("@/views/index/pc/personal")
        }
      ]
    }
  ],
  mode: "hash"
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let isLogin = window.localStorage.getItem("isLogin")
  if (isLogin === "true") {
    let userInfo = window.localStorage.getItem("userInfo")
      ? JSON.parse(window.localStorage.getItem("userInfo"))
      : {}
    // let shoppingCart = window.localStorage.getItem('shoppingCart') ? JSON.parse(window.localStorage.getItem('shoppingCart')) : []
    store.dispatch("app/setUserInfo", userInfo)
    // store.dispatch('app/setShoppingCart', shoppingCart)
    next()
  } else {
    if (
      to.path != "/" &&
      to.path != "/detail" &&
      to.path != "/coursefree" &&
      to.path != "/loginstatus"
    ) {
      next("/")
    } else {
      next()
    }
  }
})

export default router
