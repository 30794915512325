<template>
  <div id="app">
    <router-view />
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";
import { throttle, getQueryVariable } from "@/utils";
import {
  wquserlogin,
  axiosBindWx,
  bindSpreadCode,
  axiosGetIsBindWx,
  getredisuserId,
} from "@/api/login.js";
import { marketing } from "@/api/shopping.js";
import Config from "@/config/config";
import { isWeiXin } from "@/utils";

export default {
  data() {
    return {
      showiframe: false,
    };
  },
  computed: {
    ...mapGetters(["device"]),
  },
  created() {
    const spcode = getQueryVariable("spreadCode");
    const spreadCode = spcode || localStorage.getItem("spreadCode") || 0;
    localStorage.setItem("spreadCode", spreadCode);
    console.log(spreadCode, "spreadCode")
    window.addEventListener("resize", this.resizeChange, true);
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(localStorage.getItem("store"))
        )
      );
    }
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    // resize节流
    this.resizeChange = throttle(this.resizeChange, 200);
    this.resizeChange();

    console.log(this.device, "device");

    const uniqueId = getQueryVariable("uniqueId");
    if (uniqueId) {
      wquserlogin({
        uniqueId: uniqueId,
        spreadCode: spreadCode,
        type: this.device === "m" ? 1 : 0,
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            let resUserinfo = res.data.data;
            window.localStorage.setItem("isLogin", true);
            window.localStorage.setItem(
              "userInfo",
              JSON.stringify(resUserinfo)
            );
            this.$store.dispatch("app/setUserInfo", resUserinfo);
            this.bindSpCode(resUserinfo.id);
            // if (this.$route.path === "/loginstatus") {
            //   window.parent.reloadPage();
            // }
            if (window.opener) {
              window.opener.location = "javascript:reloadPage();";
              window.opener = null;
              window.close();
            } else if (this.$route.path === "/loginstatus") {
              setTimeout(() => {
                this.$router.replace("/");
              }, 200);
            }
          }
        }
      });
      setTimeout(() => {
        this.showiframe = true;
      }, 200);
    }
    const code = getQueryVariable("code");

    if (code) {
      // Dialog({ message: '微信返回码：'+code });
      this.bingWx(code);
    }
    // this.gettime();
    window.addEventListener("storage", (e) => {
      // callback
      if (e.key === "userInfo" && e.newValue == "") {
        this.$store.dispatch("app/setUserInfo", {});
        this.$store.dispatch("app/setShoppingCart", []);
        this.$router.replace({
          path: "/",
          query: { uniqueId: "" },
        });
        location.reload();
      }
    });
  },
  methods: {
    gettime() {
      marketing().then((res) => {
        const { data } = res;
        if (data.code == 0) {
          const obj = data.data || {};
          localStorage.setItem("yhdata", JSON.stringify(obj));
        }
      });
    },
    resizeChange() {
      if (document.documentElement.clientWidth > 800 && !this.is_weixn()) {
        // 默认设置当屏幕宽度 > 1000 时，为PC端
        this.$store.dispatch("app/setDevice", "pc");
        localStorage.setItem("device", "pc");
      } else {
        // 默认设置当屏幕宽度 <= 1000 时，为移动端
        this.$store.dispatch("app/setDevice", "m");
        localStorage.setItem("device", "m");
      }
    },
    // 绑定微信
    bingWx(code) {
      axiosBindWx({ code: code }).then((res) => {
        const { data, status } = res;
        // alert('绑定返回：'+JSON.stringify(res))
        if (status == 200) {
          if (data.code == 0) {
          } else {
          }
        }
      });
    },
    wxLogin() {
      // 测试appid  wx53fa636eb1653759
      // 正式   wxcf7cf1175d546c26
      let callback_url = Config.gkurl + "/loading.html";
      let mp_callback_url =
        Config.wqhomeurl +
        "/mp/public/callback?from=" +
        encodeURIComponent(callback_url);
      let authorize_url =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        Config.wxappid;

      authorize_url +=
        "&redirect_uri=" +
        encodeURIComponent(mp_callback_url) +
        "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
      window.location = authorize_url;
    },
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    bindSpCode(id) {
      const uid = id ? id : this.$store.state.app.userInfo.id;
      const spreadCode = localStorage.getItem("spreadCode");
      if (spreadCode != 0) {
        uid &&
          bindSpreadCode(uid, spreadCode)
            .then((res) => {
              if (this.is_weixn()) {
                this.getWxBind();
              }
            })
            .catch((err) => {
              if (this.is_weixn()) {
                this.getWxBind();
              }
            });
      } else {
        if (this.is_weixn()) {
          this.getWxBind();
        }
      }
    },
    getWxBind() {
      axiosGetIsBindWx().then((res) => {
        const { data, status } = res;
        if (status == 200) {
          // alert('是否绑定微信'+data.code)
          if (data.code == 1) {
          } else if (data.code == 2) {
            this.$store.dispatch("app/setIsBindWx", true);
          } else {
            // 如果微信没有绑定就绑定
            this.wxLogin();
          }
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      const uid = this.$store.state.app.userInfo.id;
      const spreadCode = localStorage.getItem("spreadCode");
      if (spreadCode != 0) {
        uid && bindSpreadCode(uid, spreadCode).then((res) => {});
      }
      window.addEventListener("resize", this.resizeChange, true);
      this.resizeChange();
    });
    setTimeout(() => {
      const uid = this.$store.state.app.userInfo.id;
      const spreadCode = localStorage.getItem("spreadCode");
      if (spreadCode != 0) {
        uid && bindSpreadCode(uid, spreadCode).then((res) => {});
      }
    }, 1000);
    const userid = this.$store.state.app.userInfo.id;
    userid &&
      getredisuserId(userid).then((res) => {
        const { data, status } = res;
        if (status == 200) {
          if (data.code == 0) {
            // let resUserinfo = data.data || {};
            window.localStorage.setItem("isLogin", true);
            // window.localStorage.setItem(
            //   "userInfo",
            //   JSON.stringify(resUserinfo)
            // );
            // this.$store.dispatch("app/setUserInfo", resUserinfo);
            if (!data.data) {
              this.$store.dispatch("app/setUserInfo", {});
              this.$store.dispatch("app/setShoppingCart", []);
              localStorage.setItem("isLogin", false);
              localStorage.setItem("userInfo", "");
              localStorage.setItem("yhdata", "");
            }
          } else {
            this.$store.dispatch("app/setUserInfo", {});
            this.$store.dispatch("app/setShoppingCart", []);
            localStorage.setItem("isLogin", false);
            localStorage.setItem("userInfo", "");
            localStorage.setItem("yhdata", "");
          }
        }
      });
    const that = this;
    window.reloadPage = function reloadPage() {
      if (localStorage.getItem("store")) {
        that.$store.replaceState(
          Object.assign(
            {},
            that.$store.state,
            JSON.parse(localStorage.getItem("store"))
          )
        );
      }
      history.go(0);
      document.location = document.location;
      document.location.reload();
    };
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeChange, false);
    localStorage.setItem("tourl", "");
  },
  created() {
    if(isWeiXin()){
      console.log('微信环境')
    }else{
      console.log('非微信环境')
    }
  },
};
</script>
<style lang="scss">
#app {
min-width: 360px;
  width: 100%;
  height: 100%;
}
.yhbox {
  background-color: transparent !important;
  .yhdialog {
    margin-bottom: 50px;
  }

  .van-icon-close {
    position: absolute;
    font-size: 30px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    color: #fff;
    cursor: pointer;
  }
  .yhimg {
    border-radius: 16px;
    width: 100%;
    max-width: 800px;
    vertical-align: middle;
  }
}
.password_dialog {
  z-index: 3000;
  .el-dialog {
    width: 360px;
                min-width: 360px;
    border-radius: 10px;
  }
  .el-dialog__body {
    width: 380px;
    height: 450px;
    padding: 0;
    margin: 0;
    overflow: hidden;
 
  }
}
.login_dialog {
  .el-dialog {
    width: 340px;
    border-radius: 10px;
  }

  .el-dialog__body {
    width: 340px;
                min-width: 340px;
    height: 380px;
    padding: 0;
    margin: 0;
    overflow: hidden;
 
  }
}
.el-message {
  top: 45% !important;
}
</style>