const isprod = location.host.indexOf("aigk985.com") !== -1;
console.log("host", location.host, isprod);

export default {
  /**
   * @description api请求基础路径
   */
  baseUrl: {
    // 测试appid  wx53fa636eb1653759
    // 正式   wxcf7cf1175d546c26
    // dev: 'http://192.168.1.34:8385/apis/',
    // dev: 'https://back.gk211985.com/apis/',

    dev: isprod
      ? "https://aigk985.com:7443/apis/"
      : "https://aigk985.com:7443/apis/",//"https://localhost:7443/apis/",
    pro: isprod
      ? "https://aigk985.com:7443/apis/"
      : "https://localhost:7443/apis/",

    // dev: 'https://gk.wqketang.com:8092/apis/',
    // pro: 'https://gk.wqketang.com:8092/apis/',
  },
  gkurl: isprod ? "https://gk.wqketang.com" : "https://wqtest.tfedu.net",
  wxappid: isprod ? "wxcf7cf1175d546c26" : "wx53fa636eb1653759",
  appId: isprod ? "wqgaokao-prod" : "wqggaokao",
  wqurl: isprod ? "https://mu.wqketang.com" : "https://test.m.wqxuetang.com",
  wqhomeurl: isprod ? "https://www.wqketang.com" : "https://www.wqketang.net",
  wqcurl: isprod ? "https://www.wqketang.com" : "http://test.v2.wqketang.cn",
  seturlpc: isprod
    ? "https://u.wqketang.com/index/setting"
    : "https://test.u.wqxuetang.com/index/setting",
  courseId: isprod ? "89681" : "286",
  vipccourseId: isprod ? '89812' : '2089',
  fileServerUrl: 'https://down.tfedu.net/nas/zhiboke/',
  // 正式
  // gkurl: 'http://aigk985.com.com',
  // wxappid: 'wxcf7cf1175d546c26',
  // appId: 'wqgaokao-prod',
  // wqurl: 'https://mu.wqketang.com',
  // wqhomeurl:'https://www.wqketang.com'
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: "home",
  yw: [
    {
      "id": 10000,
      "navi_name": "高考语文专家直播课程介绍",
      "parent_id": 0,
      "course_id": 89866
    },
    {
      "id": 10001,
      "navi_name": "高考语文备考概说（上）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10006,
      "navi_name": "高考语文备考概说（下）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10011,
      "navi_name": "语言运用和表达（一）：词句类",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10016,
      "navi_name": "现代文“检索”类阅读",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10021,
      "navi_name": "文言文阅读",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10026,
      "navi_name": "现代文“加工”类阅读",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10031,
      "navi_name": "古代诗歌阅读",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10036,
      "navi_name": "写作（一）：高考写作的源头活水",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10041,
      "navi_name": "真题精讲（一）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10046,
      "navi_name": "语言运用和表达（二）：语段类",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10051,
      "navi_name": "现代文“检索”类阅读（二）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10056,
      "navi_name": "文言文阅读（二）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10061,
      "navi_name": "现代文“加工”类阅读（二）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10066,
      "navi_name": "写作（二）：写作成功的必由之路",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10071,
      "navi_name": "真题精讲（二）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10076,
      "navi_name": "语言运用和表达（三）：综合类",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10081,
      "navi_name": "文言文阅读（三）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10086,
      "navi_name": "现代文“加工”类阅读（三）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10091,
      "navi_name": "古代诗歌阅读（二）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10096,
      "navi_name": "写作（三）：作文体裁的规范与个性",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10101,
      "navi_name": "真题精讲（三）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10106,
      "navi_name": "文学文化经典阅读",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10111,
      "navi_name": "写作（四）：结构、语言和修改",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10116,
      "navi_name": "典型模拟题精讲（一）",
      "parent_id": 10000,
      "course_id": 89867
    },
    {
      "id": 10121,
      "navi_name": "典型模拟题精讲（二）",
      "parent_id": 10000,
      "course_id": 89867
    }
  ],
  sx: [
    {
      "id": 20000,
      "navi_name": "高考数学专家直播课程介绍",
      "parent_id": 0,
      "course_id": 89867
    },
    {
      "id": 20001,
      "navi_name": "函数思想和函数方法（一）",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20006,
      "navi_name": "函数思想和函数方法（二）",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20011,
      "navi_name": "基本初等函数的图像和性质",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20016,
      "navi_name": "抽象函数，高考中关于函数的选择题和填空题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20021,
      "navi_name": "角，三角函数及其图像",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20026,
      "navi_name": "三角函数综合题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20031,
      "navi_name": "三角函数内的三角问题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20036,
      "navi_name": "解平面向量问题的基本思想和基本方法",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20041,
      "navi_name": "不等关系和不等式",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20046,
      "navi_name": "解析几何的选择题和填空题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20051,
      "navi_name": "解析几何综合题（一）",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20056,
      "navi_name": "解析几何综合题（二）",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20061,
      "navi_name": "解析几何综合题（三）",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20066,
      "navi_name": "如何解立体几何的选择题和填空题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20071,
      "navi_name": "高考中的立体几何选择题和填空题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20076,
      "navi_name": "空间中的角与距离",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20081,
      "navi_name": "数列的选择题和填空题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20086,
      "navi_name": "数列综合题（一）归纳与递推",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20091,
      "navi_name": "数列综合题（二）数列的证明题；函数与数列",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20096,
      "navi_name": "导数和曲线的切线",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20101,
      "navi_name": "切线，曲线的凹凸性和不等关系",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20106,
      "navi_name": "利用导数求“参数的取值范围”",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20111,
      "navi_name": "“同构”及其应用",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20116,
      "navi_name": "高考中的函数与导数综合题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20121,
      "navi_name": "函数极值点偏移问题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20126,
      "navi_name": "模拟考，联考试题选讲(一)选择题，填空题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20131,
      "navi_name": "模拟考，联考试题选讲(一)选择题，填空题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20136,
      "navi_name": "模拟考联考试题选讲（二）解析几何，函数与导数",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20141,
      "navi_name": "创新型试题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20146,
      "navi_name": "高考中涉及“数学文化”的试题选讲",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20151,
      "navi_name": "自拟模拟试题",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20156,
      "navi_name": "一模试题精选精讲（一）",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20161,
      "navi_name": "一模试题精选精讲（二）",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20166,
      "navi_name": "二模试题精选精讲",
      "parent_id": 20000,
      "course_id": 89867
    },
    {
      "id": 20171,
      "navi_name": "考前嘱托",
      "parent_id": 20000,
      "course_id": 89867
    }
  ],
  yy: [
    {
      "id": 30000,
      "course_id": 89868,
      "navi_name": "高考英语专家直播课程介绍",
      "parent_id": 0
    },
    {
      "id": 30001,
      "navi_name": "科学备考方略+科学反思",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30006,
      "navi_name": "基础语法之一——时态语态",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30011,
      "navi_name": "基础语法之二——非谓语动词",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30016,
      "navi_name": "基础语法之三——从句和其他",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30021,
      "navi_name": "听力理解",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30026,
      "navi_name": "语法填空+短文改错",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30031,
      "navi_name": "阅读理解",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30036,
      "navi_name": "应用文写作",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30041,
      "navi_name": "完形填空",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30046,
      "navi_name": "应用文写作2",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30051,
      "navi_name": "文章续写",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30056,
      "navi_name": "英语高考答题技巧之一·阅读理解",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30061,
      "navi_name": "英语高考答题技巧之二·完形填空",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30066,
      "navi_name": "英语高考答题技巧之三·语法填空",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30071,
      "navi_name": "英语高考答题技巧之四·短文改错",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30076,
      "navi_name": "书面表达小贴士",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30081,
      "navi_name": "高考真题精选精讲一",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30086,
      "navi_name": "高考真题精选精讲二",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30091,
      "navi_name": "各地特色题型精讲",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30096,
      "navi_name": "各地典型模拟题精讲（一）",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30101,
      "navi_name": "各地典型模拟题精讲（二）",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30106,
      "navi_name": "各地典型模拟题精讲（三）",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30111,
      "navi_name": "各地典型模拟题精讲（四）",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30116,
      "navi_name": "各地典型模拟题精讲（五）",
      "parent_id": 30000,
      "course_id": 89868
    },
    {
      "id": 30121,
      "navi_name": "考前叮嘱",
      "parent_id": 30000,
      "course_id": 89868
    }
  ],
  wl: [
    {
      "id": 40000,
      "course_id": 89872,
      "navi_name": "高考物理专家直播课程介绍",
      "parent_id": 0
    },
    {
      "id": 40001,
      "navi_name": "匀变速直线运动",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40006,
      "navi_name": "共点力的平衡",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40011,
      "navi_name": "力与运动",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40016,
      "navi_name": "力与运动的应用",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40021,
      "navi_name": "功和能",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40026,
      "navi_name": "动量和冲量",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40031,
      "navi_name": "天体与卫星的运动",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40036,
      "navi_name": "静电场的基本性质及电容器",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40041,
      "navi_name": "带电质点在电场中的运动",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40046,
      "navi_name": "磁场的基本性质安培力与洛伦兹力",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40051,
      "navi_name": "带电粒子在磁场中的运动",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40056,
      "navi_name": "电磁感应的基本问题",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40061,
      "navi_name": "交流电基础",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40066,
      "navi_name": "力学试验",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40071,
      "navi_name": "电学实验1：伏安法",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40076,
      "navi_name": "电学实验2：改装表1",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40081,
      "navi_name": "电学实验3：拓展实验2",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40086,
      "navi_name": "机械振动和机械波",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40091,
      "navi_name": "热学",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40096,
      "navi_name": "几何光学",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40101,
      "navi_name": "物理光学原子和原子核",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40106,
      "navi_name": "连接体问题",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40111,
      "navi_name": "板块模型",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40116,
      "navi_name": "含有弹簧的综合问题",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40121,
      "navi_name": "含有传送带的综合问题",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40126,
      "navi_name": "碰撞问题",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40131,
      "navi_name": "带电物体在复合场中的运动",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40136,
      "navi_name": "电磁感应中能的转化和守恒（一）",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40141,
      "navi_name": "电磁感应中能的转化和守恒（二）",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40146,
      "navi_name": "各地典型模拟题精讲一",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40151,
      "navi_name": "各地典型模拟题精讲二",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40156,
      "navi_name": "各地典型模拟题精讲三",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40161,
      "navi_name": "各地典型模拟题精讲四",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40166,
      "navi_name": "各地典型模拟题精讲五",
      "parent_id": 40000,
      "course_id": 89872
    },
    {
      "id": 40171,
      "navi_name": "考前热点题精讲及考前叮嘱",
      "parent_id": 40000,
      "course_id": 89872
    }
  ],
  hx: [
    {
      "id": 50000,
      "course_id": 89873,
      "navi_name": "高考化学专家直播课程介绍",
      "parent_id": 0
    },
    {
      "id": 50001,
      "navi_name": "物质的分类及性质、离子反应",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50006,
      "navi_name": "氧化还原反应、物质的量",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50011,
      "navi_name": "反应热、电化学（一）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50016,
      "navi_name": "电化学（二）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50021,
      "navi_name": "综合问题解决（一）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50026,
      "navi_name": "化学反应的调控 视频",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50031,
      "navi_name": "化学反应的调控·综合问题解决（二）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50036,
      "navi_name": "水溶液中的化学之一",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50041,
      "navi_name": "水溶液中的化学之二",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50046,
      "navi_name": "金属及其化合物 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50051,
      "navi_name": "非金属及其化合物视频",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50056,
      "navi_name": "物质结构与性质",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50061,
      "navi_name": "综合问题解决（三）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50066,
      "navi_name": "有机化学",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50071,
      "navi_name": "化学实验专题一：基本实验 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50076,
      "navi_name": "化学实验专题之二：综合实验 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50081,
      "navi_name": "拿下有机试题之一 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50086,
      "navi_name": "拿下有机试题之二 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50091,
      "navi_name": "结构知识再回顾",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50096,
      "navi_name": "反应原理综合应用 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50101,
      "navi_name": "元素综合应用 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50106,
      "navi_name": "无机化学综合应用解题基本思路、方法（三）·工业流程专题 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50111,
      "navi_name": "化学实验专题（三）·综合、探究 ",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50116,
      "navi_name": "高考真题精选精讲（一）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50121,
      "navi_name": "高考真题精选精讲（二）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50126,
      "navi_name": "模拟题精讲（一）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50131,
      "navi_name": "模拟题精讲（二）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50136,
      "navi_name": "模拟题精讲（三）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50141,
      "navi_name": "典型模拟题精讲（四）",
      "parent_id": 50000,
      "course_id": 89873
    },
    {
      "id": 50146,
      "navi_name": "考前热点题精讲及考前叮嘱 ",
      "parent_id": 50000,
      "course_id": 89873
    }
  ],
  sw: [
    {
      "id": 60000,
      "course_id": 89874,
      "navi_name": "高考生物专家直播课程介绍",
      "parent_id": 0
    },
    {
      "id": 60001,
      "navi_name": "细胞是生物体结构和功能的基本单位",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60006,
      "navi_name": "细胞的生存需要能量和营养物质",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60011,
      "navi_name": "细胞的生命历程与癌变",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60016,
      "navi_name": "遗传的分子基础",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60021,
      "navi_name": "遗传的细胞学基础",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60026,
      "navi_name": "遗传的基本规律",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60031,
      "navi_name": "生物的变异",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60036,
      "navi_name": "植物激素和环境因素对植物生命活动的调节",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60041,
      "navi_name": "神经-体液-免疫调节共同维持动物内环境稳态",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60046,
      "navi_name": "种群与群落",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60051,
      "navi_name": "生态系统与生态工程",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60056,
      "navi_name": "生物技术与工程",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60061,
      "navi_name": "生物学实验与科学探究（一）基础实验",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60066,
      "navi_name": "生物学实验与科学探究（二）科学探究",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60071,
      "navi_name": "生物学答题技巧01应用“生命观念”答题",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60076,
      "navi_name": "生物学答题技巧02应用“科学探究”及“科学思维”方法答题",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60081,
      "navi_name": "生物学答题技巧03综合应用生物学原理、研究方法答题（一）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60086,
      "navi_name": "生物学答题技巧04综合应用生物学原理、研究方法答题（二）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60091,
      "navi_name": "高考试题精选精讲（一）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60096,
      "navi_name": "高考试题精选精讲（二）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60101,
      "navi_name": "高考试题精选精讲（三）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60106,
      "navi_name": "各地典型模拟题精讲（一）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60111,
      "navi_name": "各地典型模拟题精讲（二）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60116,
      "navi_name": "各地典型模拟题精讲（三）",
      "parent_id": 60000,
      "course_id": 89874
    },
    {
      "id": 60121,
      "navi_name": "考前热点题精讲及考前叮嘱",
      "parent_id": 60000,
      "course_id": 89874
    }
  ],
  ls: [
    {
      "id": 80000,
      "course_id": 89870,
      "navi_name": "高考历史专家直播课程介绍",
      "parent_id": 0
    },
    {
      "id": 80001,
      "navi_name": "走进中华古代文明之一·从蒙昧到文明、甲骨文明与青铜文明、大变革的时代",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80006,
      "navi_name": "走进中华古代文明之二·农耕文明的奠基、文明的对撞与交融、走进盛唐",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80011,
      "navi_name": "走进中华古代文明之三·农耕文明的发展、宋元文化的张力、唐宋变革的思考",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80016,
      "navi_name": "走进中华古代文明之四·农耕文明的嬗变、走进康乾盛世、历史中的“人”与“事”",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80021,
      "navi_name": "中国近代社会的转型（一）",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80026,
      "navi_name": "中国近代社会的转型（二）",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80031,
      "navi_name": "中国近代社会的转型（三）",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80036,
      "navi_name": "中国近代社会的转型（四）",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80041,
      "navi_name": "人类文明的历程（一）——世界古代史",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80046,
      "navi_name": "人类文明的历程（二）——走向近代社会",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80051,
      "navi_name": "人类文明的历程（三）——工业革命及其影响",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80056,
      "navi_name": "人类文明的历程（四）——当代世界的发展",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80061,
      "navi_name": "政治史专题复习",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80066,
      "navi_name": "经济史专题复习",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80071,
      "navi_name": "文化史专题复习",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80076,
      "navi_name": "“秒杀〞高考选择题",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80081,
      "navi_name": "历史问题的设问与作答思路",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80086,
      "navi_name": "历史信息的提取与整合",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80091,
      "navi_name": "如何快速高效地调动和运用知识",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80096,
      "navi_name": "命题趋势与学科热点（一）·历史研究热点问题与高考命题趋势",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80101,
      "navi_name": "命题趋势与学科热点（二）·中国教育改革热点与高考命题趋势",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80106,
      "navi_name": "命题趋势与学科热点（三）·立德树人在高考命题中的体现",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80111,
      "navi_name": "“秒杀”高考试题：我们的考场思维",
      "parent_id": 80000,
      "course_id": 89870
    },
    {
      "id": 80116,
      "navi_name": "用好学科术语，展现自我风采",
      "parent_id": 80000,
      "course_id": 89870
    }
  ],
  dl:
    [
      {
        "id": 90000,
        "course_id": 89871,
        "navi_name": "高考地理专家直播课程介绍",
        "parent_id": 0
      },
      {
        "id": 90001,
        "navi_name": "地理各模块知识命题点总结(一)",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90006,
        "navi_name": "地理各模块知识命题点总结(二)",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90011,
        "navi_name": "自然地理难点突破（一）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90016,
        "navi_name": "自然地理难点突破（二）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90021,
        "navi_name": "高考地理之解题技巧专项突破（一）——答案在哪里",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90026,
        "navi_name": "高考地理之解题技巧专项突破（二）——读图技巧训练1",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90031,
        "navi_name": "高考地理之解题技巧专项突破（三）——读图技巧训练2",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90036,
        "navi_name": "高考地理之解题技巧专项突破（四）——解题技巧训练",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90041,
        "navi_name": "“读”高考地理命题思维 在解题中灵活应用",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90046,
        "navi_name": "地理过程的分析技巧与常见的地理过程归纳",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90051,
        "navi_name": "高考答题错误原因分析",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90056,
        "navi_name": "利用高考试题进行变式训练（一）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90061,
        "navi_name": "利用高考试题进行变式训练（二）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90066,
        "navi_name": "解题技巧分析与训练(一）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90071,
        "navi_name": "解题技巧分析与训练（二）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90076,
        "navi_name": "高考真题精选精讲（一）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90081,
        "navi_name": "高考真题精选精讲（二）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90086,
        "navi_name": "高考真题精选精讲（三）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90091,
        "navi_name": "各地典型模拟题精讲(一）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90096,
        "navi_name": "各地典型模拟题精讲(二）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90101,
        "navi_name": "各地典型模拟题精讲(三）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90106,
        "navi_name": "各地典型模拟题精讲(四）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90111,
        "navi_name": "各地典型模拟题精讲(五）",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90116,
        "navi_name": "品读高考地理评分规则",
        "parent_id": 90000,
        "course_id": 89871
      },
      {
        "id": 90121,
        "navi_name": "考前叮嘱",
        "parent_id": 90000,
        "course_id": 89871
      }
    ],
  zz: [
    {
      "id": 70000,
      "course_id": 89869,
      "navi_name": "高考政治专家直播课程介绍",
      "parent_id": 0
    },
    {
      "id": 70001,
      "navi_name": "充分发挥市场作用",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70006,
      "navi_name": "更好发挥政府作用",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70011,
      "navi_name": "推动经济高质量发展",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70016,
      "navi_name": "我国的分配制度与共同富裕目标的实现",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70021,
      "navi_name": "坚持和完善中国共产党领导的多党合作和政治协商制度",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70026,
      "navi_name": "坚持和完善我国的根本政治制度",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70031,
      "navi_name": "坚持和完善民族区域自治制度和基层群众自治制度",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70036,
      "navi_name": "法治",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70041,
      "navi_name": "发展中国特色社会主义文化",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70046,
      "navi_name": "中国特色社会主义",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70051,
      "navi_name": "哲学与辩证唯物主义哲学",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70056,
      "navi_name": "唯物辩证法的联系观和发展观",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70061,
      "navi_name": "唯物辩证法的矛盾观",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70066,
      "navi_name": "认识论",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70071,
      "navi_name": "历史唯物主义",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70076,
      "navi_name": "《当代国际政治与经济》上",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70081,
      "navi_name": "当代国际政治与经济",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70086,
      "navi_name": "社会争议解决",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70091,
      "navi_name": "社会争议解决",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70096,
      "navi_name": "树立科学思维观念、遵循逻辑思维规则",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70101,
      "navi_name": "运用辩证思维方法  提高创新思维能力",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70106,
      "navi_name": "政治解题思路专题二",
      "parent_id": 70000,
      "course_id": 89869
    },
    {
      "id": 70111,
      "navi_name": "解题一",
      "parent_id": 70000,
      "course_id": 89869
    }
  ],
  goodsList: [
    {
      id: 426,
      courseId: "89866",
      courseData: {
        id: 2093,
        categoryId: 9,
        logoId: "public/img/yw-middle.jpg",
        //包月价格
       //月卡价格
       monthlyCardPrice: 299.00,
       //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 9,
        name: "语文高考专家辅导课程",
        whetherSale: false,
        lessonPeriod: 50,
        courseId: 89866
      },
      courseName: "语文高考专家辅导课程",
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F943790_yw-%E8%AF%A6%E9%A1%B5-2.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89866",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/yw.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/yw.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/yw.jpg",
      tradeGoodsId: 2093
    },
    {
      id: 425,
      courseId: "89867",
      courseName: "数学高考专家辅导课程",
      courseData: {
        id: 2094,
        categoryId: 10,
        logoId: "public/img/sx-middle.jpg",
        //包月价格
       //月卡价格
       monthlyCardPrice: 299.00,
       //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 10,
        name: "数学高考专家辅导课程",
        whetherSale: false,
        lessonPeriod: 70,
        courseId: 89867
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F234251_%E6%95%B0%E5%AD%A6-%E8%AF%A6%E9%A1%B5.png\" /></p>\r\n",
      url: "https://www.zbaiw.com:7777/?sid=2",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/sx.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/sx.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/sx.jpg",
      tradeGoodsId: 2094
    },
    {
      id: 424,
      courseId: "89868",
      courseName: "英语高考专家辅导课程",
      courseData: {
        id: 2095,
        categoryId: 8,
        logoId: "public/img/yy-middle.jpg",
        //包月价格
       //月卡价格
       monthlyCardPrice: 299.00,
       //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 8,
        name: "英语高考专家辅导课程",
        whetherSale: false,
        lessonPeriod: 50,
        courseId: 89868
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F434062_%E8%8B%B1%E8%AF%AD-%E8%AF%A6%E9%A1%B5_2.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89868",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/yy.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/yy.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/yy.jpg",
      tradeGoodsId: 2095
    },

    {
      id: 420,
      courseId: "89872",
      courseName: "物理高考专家辅导课程",
      courseData: {
        id: 2096,
        categoryId: 12,
        logoId: "public/img/wl-middle.jpg",
        //包月价格
       //月卡价格
       monthlyCardPrice: 299.00,
       //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 12,
        name: "高考专家辅导课程（英语）",
        whetherSale: false,
        lessonPeriod: 70,
        courseId: 89872
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F654403_%E7%89%A9%E7%90%86-%E8%AF%A6%E9%A1%B5.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89872",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/wl.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/wl.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/wl.jpg",
      tradeGoodsId: 2096
    },
    {
      id: 419,
      courseId: "89873",
      courseName: "化学高考专家辅导课程",
      courseData: {
        id: 2097,
        categoryId: 11,
        logoId: "public/img/hx-middle.jpg",
        //包月价格
       //月卡价格
       monthlyCardPrice: 299.00,
       //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 11,
        name: "化学高考专家辅导课程",
        whetherSale: false,
        lessonPeriod: 60,
        courseId: 89873
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F590006_%E5%8C%96%E5%AD%A6-%E8%AF%A6%E9%A1%B5-%E6%94%B9.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89873",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/hx.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/hx.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/hx.jpg",
      tradeGoodsId: 2097
    },
    {
      id: 418,
      courseId: "89874",
      courseName: "生物高考专家辅导课程",
      courseData: {
        id: 2098,
        categoryId: 14,
        logoId: "public/img/sw-middle.jpg",
        //包月价格
       //月卡价格
       monthlyCardPrice: 299.00,
       //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 14,
        name: "生物高考专家辅导课程",
        whetherSale: false,
        lessonPeriod: 50,
        courseId: 89874
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F233059_%E7%94%9F%E7%89%A9-%E8%AF%A6%E9%A1%B5.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89874",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/sw.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/sw.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/sw.jpg",
      tradeGoodsId: 2098
    },
    {
      id: 423,
      courseId: "89869",
      courseName: "政治高考专家辅导课程",
      courseData: {
        id: 2101,
        categoryId: 17,
        logoId: "public/img/zz-middle.jpg",
        //包月价格
       //月卡价格
       monthlyCardPrice: 299.00,
       //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 8,
        name: "政治高考专家辅导课程",
        whetherSale: false,
        lessonPeriod: 46,
        courseId: 89869
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F223932_%E6%94%BF%E6%B2%BB-%E8%AF%A6%E9%A1%B5.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89869",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/zz.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/zz.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/zz.jpg",
      tradeGoodsId: 2101
    },
    {
      id: 422,
      courseId: "89870",
      courseName: "历史高考专家辅导课程",
      courseData: {
        id: 2099,
        categoryId: 15,
        logoId: "public/img/ls-middle.jpg",  
         //包月价格
        //月卡价格
        monthlyCardPrice: 299.00,
        //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 15,
        name: "高考专家辅导课程（英语）",
        whetherSale: false,
        lessonPeriod: 48,
        courseId: 89870
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F456384_%E5%8E%86%E5%8F%B2-%E8%AF%A6%E9%A1%B5.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89870",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/ls.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/ls.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/ls.jpg",
      tradeGoodsId: 2099
    },
    {
      id: 421,
      courseId: "89871",
      courseName: "地理高考专家辅导课程",
      courseData: {
        id: 2100,
        categoryId: 16,
        logoId: "public/img/dl-middle.jpg",   //包月价格
        //月卡价格
        monthlyCardPrice: 299.00,
        //包年价格
        markingPrice: 1980.00,
        scribingPrice: 1980.00,
        groupPrice: 0.00,
        detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
        publishTime: 1687437656000,
        status: 1,
        stock: 100000,
        deleted: false,
        creatorId: 467,
        createdTime: 1686022358000,
        objectId: 16,
        name: "地理高考专家辅导课程",
        whetherSale: false,
        lessonPeriod: 50,
        courseId: 89871
      },
      type: 1,
      about: "<p><img alt=\"\" class=\"img-responsive\" src=\"https://publicoss.izhixue.cn/wqketang%2F792222_%E5%9C%B0%E7%90%86-%E8%AF%A6%E9%A1%B5.png\" /></p>\r\n",
      url: "https://www.wqketang.com/course/89871",
      largePicture: "https://down.tfedu.net/nas/zhiboke/public/img/dl.jpg",
      middlePicture: "https://down.tfedu.net/nas/zhiboke/public/img/dl.jpg",
      smallPicture: "https://down.tfedu.net/nas/zhiboke/public/img/dl.jpg",
      tradeGoodsId: 2100
    },




  ],
  tradeGoodsList: [{
    id: 2093,
    categoryId: 9,
    logoId: "public/img/yw-middle.jpg",
    //包月价格
    //月卡价格
    monthlyCardPrice: 299.00,
    //包年价格
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 9,
    name: "语文高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 50,
    courseId: 89866
  },
  {
    id: 2094,
    categoryId: 10,
    logoId: "public/img/sx-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 10,
    name: "数学高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 70,
    courseId: 89867
  },
  {
    id: 2095,
    categoryId: 11,
    logoId: "public/img/yy-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 11,
    name: "英语高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 50,
    courseId: 89868
  },
  {
    id: 2096,
    categoryId: 12,
    logoId: "public/img/wl-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 12,
    name: "物理高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 70,
    courseId: 89872
  },
  {
    id: 2097,
    categoryId: 13,
    logoId: "public/img/hx-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 13,
    name: "化学高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 60,
    courseId: 89873
  },
  {
    id: 2098,
    categoryId: 14,
    logoId: "public/img/sw-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 14,
    name: "生物高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 50,
    courseId: 89874
  },
  {
    id: 2099,
    categoryId: 15,
    logoId: "public/img/ls-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 15,
    name: "历史高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 48,
    courseId: 89870
  },
  {
    id: 2100,
    categoryId: 16,
    logoId: "public/img/dl-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 16,
    name: "地理高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 50,
    courseId: 89871
  },
  {
    id: 2101,
    categoryId: 17,
    logoId: "public/img/zz-middle.jpg",
    //月卡价格
    monthlyCardPrice: 299.00,
    markingPrice: 1980.00,
    scribingPrice: 1980.00,
    groupPrice: 0.00,
    detailUrl: "/goods/detail/2095/7cf6c944-2054-4c68-afeb-bc93f18b6c22.html",
    publishTime: 1687437656000,
    status: 1,
    stock: 100000,
    deleted: false,
    creatorId: 467,
    createdTime: 1686022358000,
    objectId: 8,
    name: "政治高考专家辅导课程",
    whetherSale: false,
    lessonPeriod: 46,
    courseId: 89869
  }],
  tradeGoodsInfoList: [
    {
      id: 2078,
      name: "2023高考专家辅导课程（语文）",
      category_id: 8,
      logo_id: "public/img/yw-middle.jpg",

      logotwo_id: "2",
      marking_price: 3500.0,
      scribing_price: 3500.0,
      group_price: 0.009999999776482582,
      detail_url: "/goods/detail/2078/5b55214d-dc1b-4c4e-931d-4c718ab962fc.html",
      publish_time: "13/10/2022 09:30:52",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "15/8/2022 11:19:01",
      update_time: "2/2/2024 16:28:43",
      object_id: 288,
      lesson_period: 50,
      course_id: "89680"
    },
    {
      id: 2079,
      name: "2023高考专家辅导课程（数学）",
      category_id: 8,
      logo_id: "//goods/picture/76f620aa-e435-409c-92da-f794e3585e98.jpg",
      logotwo_id: "3",
      marking_price: 4900.0,
      scribing_price: 4900.0,
      group_price: 0.019999999552965165,
      detail_url: "/goods/detail/2079/53082397-7c40-45cb-8b24-f82f06f801b3.html",
      publish_time: "21/11/2022 09:52:19",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "15/8/2022 11:21:07",
      update_time: "7/6/2023 21:26:07",
      object_id: 287,
      lesson_period: 70,
      course_id: "89679"
    },
    {
      id: 2080,
      name: "2023高考专家辅导课程（英语）",
      category_id: 8,
      logo_id: "//goods/picture/827b45f5-b4d3-4c19-ac15-feff58b37c67.jpg",
      logotwo_id: "4",
      marking_price: 3500.0,
      scribing_price: 3500.0,
      group_price: 3150.0,
      detail_url: "/goods/detail/2080/4d59285c-6dcc-400d-bb79-22aa9521c08a.html",
      publish_time: "13/10/2022 09:33:45",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "15/8/2022 11:21:47",
      update_time: "7/6/2023 21:26:08",
      object_id: 289,
      lesson_period: 50,
      course_id: "89678"
    },
    {
      id: 2081,
      name: "2023高考专家辅导课程（物理）",
      category_id: 8,
      logo_id: "//goods/picture/74c2b6d7-4214-49c5-9408-c5f449a1081e.jpg",
      logotwo_id: "5",
      marking_price: 4900.0,
      scribing_price: 4900.0,
      group_price: 4410.0,
      detail_url: "/goods/detail/2081/e189b932-506b-442c-a27f-3c6f2abaa98b.html",
      publish_time: "13/10/2022 09:34:26",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "24/8/2022 12:13:06",
      update_time: "7/6/2023 21:26:09",
      object_id: 290,
      lesson_period: 70,
      course_id: "89677"
    },
    {
      id: 2082,
      name: "2023高考专家辅导课程（化学）",
      category_id: 8,
      logo_id: "//goods/picture/2e421c9c-a5db-4ef7-b315-4c9e8f806179.jpg",
      logotwo_id: "6",
      marking_price: 4200.0,
      scribing_price: 4200.0,
      group_price: 3780.0,
      detail_url: "/goods/detail/2082/11431d56-9ae3-436e-b2d0-927fd18a59d4.html",
      publish_time: "9/11/2022 09:38:32",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "24/8/2022 12:18:29",
      update_time: "7/6/2023 21:26:11",
      object_id: 291,
      lesson_period: 60,
      course_id: "89676"
    },
    {
      id: 2083,
      name: "2023高考专家辅导课程（生物）",
      category_id: 8,
      logo_id: "//goods/picture/a8dd653e-686d-42a4-904d-9670c5b4f976.jpeg",
      logotwo_id: "7",
      marking_price: 3500.0,
      scribing_price: 3500.0,
      group_price: 3150.0,
      detail_url: "/goods/detail/2083/af27607b-d4bb-436b-ac6e-4b8f22dcb65c.html",
      publish_time: "13/10/2022 09:35:45",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "24/8/2022 12:19:37",
      update_time: "7/6/2023 21:26:12",
      object_id: 292,
      lesson_period: 50,
      course_id: "89675"
    },
    {
      id: 2084,
      name: "2023高考专家辅导课程（历史）",
      category_id: 8,
      logo_id: "//goods/picture/2e02ebea-d7c7-4771-99f4-53eb167a62a5.jpg",
      logotwo_id: "8",
      marking_price: 3500.0,
      scribing_price: 3500.0,
      group_price: 3150.0,
      detail_url: "/goods/detail/2084/ec89f254-2307-49f1-820c-d8ad84e496c5.html",
      publish_time: "13/10/2022 09:35:57",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "24/8/2022 12:20:18",
      update_time: "7/6/2023 21:26:14",
      object_id: 293,
      lesson_period: 50,
      course_id: "89674"
    },
    {
      id: 2085,
      name: "2023高考专家辅导课程（地理）",
      category_id: 8,
      logo_id: "//goods/picture/6049dc67-6ff3-4623-aaf9-1c724cb93e52.jpg",
      logotwo_id: "9",
      marking_price: 3500.0,
      scribing_price: 3500.0,
      group_price: 3150.0,
      detail_url: "/goods/detail/2085/6ae2b228-e70e-424c-a166-cdc605042457.html",
      publish_time: "13/10/2022 09:36:08",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "24/8/2022 12:21:14",
      update_time: "7/6/2023 21:26:15",
      object_id: 294,
      lesson_period: 50,
      course_id: "89673"
    },
    {
      id: 2086,
      name: "2023高考专家辅导课程（政治）",
      category_id: 8,
      logo_id: "//goods/picture/4da07bb2-29e9-4a8a-9995-7445c3aac34b.jpg",
      logotwo_id: "9",
      marking_price: 3500.0,
      scribing_price: 3500.0,
      group_price: 3150.0,
      detail_url: "/goods/detail/2086/89b4f7bd-2df6-4399-836e-9e0677ca0ffc.html",
      publish_time: "14/10/2022 19:34:16",
      status: 2,
      stock: 10000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "24/8/2022 12:22:24",
      update_time: "7/6/2023 21:26:17",
      object_id: 295,
      lesson_period: 50,
      course_id: "89670"
    },
    {
      id: 2090,
      name: "2023高考专家备考锦囊",
      category_id: 8,
      logo_id: "public/img/banner3-middle.jpg",
      logotwo_id: "1",
      marking_price: 1200.0,
      scribing_price: 0.0,
      group_price: 0.0,
      detail_url: "/goods/detail/2090/b9867281-f625-4f21-92a1-fab80c326735.html",
      publish_time: "10/10/2022 11:45:53",
      status: 2,
      stock: 111111,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "8/9/2022 15:18:28",
      update_time: "2/2/2024 16:39:41",
      object_id: 287,
      lesson_period: 18,
      course_id: "89681"
    },
    {
      id: 2092,
      name: "2023高考冲刺热点专家精讲",
      category_id: 8,
      logo_id: "public/img/banner3-middle.jpg",
      logotwo_id: "0",
      marking_price: 3999.0,
      scribing_price: 3999.0,
      group_price: 0.0,
      detail_url: "/goods/detail/2092/e0d05a0f-f5ee-4cbe-8233-8a3875503750.html",
      publish_time: "16/3/2023 10:39:26",
      status: 2,
      stock: 100000,
      deleted: "0",
      creator_id: 466,
      approver_id: null,
      created_time: "3/3/2023 12:41:29",
      update_time: "13/3/2024 13:48:20",
      object_id: 288,
      lesson_period: 112,
      course_id: "89812"
    }
  ]

};
