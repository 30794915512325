import axios from '@/utils/api.request'

export const axiosGetGoodsList = (data) => {
  return axios.request({
    url: `goods/get-wq-tradegoods-list`,
    method: 'get'
  })
}
export const axiosUserGoodsDuration = (userId,tradeGoodsId,resId) => {
  return axios.request({
    url: `goods/user-goods-duration/${userId}/${tradeGoodsId}/${resId}`,
    method: 'get'
  })
}
export const getAllWqCourse = (type) => {
  return axios.request({
    url: `goods/get-all-wq-course-type/${type}`,
    method: 'get'
  })
}
export const userlearnnow = (userId,orderNo) => {
  return axios.request({
    url: `wquser/user-learn-now/${userId}/${orderNo}`,
    method: 'get'
  })
}
export const axiosAddShopping = (data) => {
  return axios.request({
    url: `shopping/add-cart`,
    method: 'post',
    data: data
  })
}

export const axiosGetShopping = (id) => {
  return axios.request({
    url: `shopping/get-cart/${id}`,
    method: 'get'
  })
}

export const axiosDelShopping = (id) => {
  return axios.request({
    url: `shopping/del-cart/${id}`,
    method: 'get'
  })
}

export const axiosOrderAdd = (data) => {
  const spcode = localStorage.getItem('spreadCode') || 0
  return axios.request({
    url: `vip/order/add`,
    method: 'post',
    data: {
      ...data,
      spreadCode:spcode
    }
  })
}

export const axiosCreatPreOrder = (data) => {
  return axios.request({
    url: `wxpay/create-vip-pre-order`,
    method: 'post',
    data: data
  })
}

export const axiosFindOrder = (id) => {
  return axios.request({
    url: `vip/order/find/${id}`,
    method: 'get'
  })
}

export const axiosAlipayPayr = (data) => {
  return axios.request({
    url: `alipay/vip/pay`,
    method: 'post',
    data: data
  })
}
export const axiosAlipayPayrwapVip = (data) => {
  return axios.request({
    url: `alipay/vip/wap-pay`,
    method: 'post',
    data: data
  })
}
export const axiosGetCalculationCart = (tradeGoodsId,userId) => {
  const spcode = localStorage.getItem('spreadCode') || 0
  return axios.request({
    url: `shopping/get-calculation-cart/${tradeGoodsId}/${spcode}/${userId}`,
    method: 'get'
  })
}

export const createWxPreOrder = (data) => {
  return axios.request({
    url: `wxpay/create-gzh-pre-order`,
    method: 'post',
    data
  })
}

export const marketing = () => {
  return axios.request({
    url: `marketing/marketing-time`,
    method: 'get'
  })
}

export const updateorder = (orderNumber) => {
  return axios.request({
    url: `vip/order/update-course-by-order-status/${orderNumber}`,
    method: 'get'
  })
}

export const addfreeorder = (data) => {
  return axios.request({
    url: `vip/order/add-free-order`,
    method: 'post',
    data
  })
}

export const userlearnnowcourseId = (userId,courseId) => {
  return axios.request({
    url: `wquse/user-learn-now-courseId/${userId}/${courseId}`,
    method: 'get'
  })
}

export const getfreestatus = (userId,tradeGoodsId) => {
  return axios.request({
    url: `wquser/get-status/${userId}/${tradeGoodsId}`,
    method: 'get'
  })
}
export const tradegoodsdetail = (courseId) => {
  return axios.request({
    url: `goods/get-wq-tradegoods-detail/${courseId}`,
    method: 'get'
  })
}
export const courseitems = (courseId) => {
  return axios.request({
    url: `goods/get-all-wq-course-items/${courseId}`,
    method: 'get'
  })
}
