import axios from "@/utils/api.request"

// 获取登录验证码
export const axiosGetLoginVerifyCode = (mobile) => {
  return axios.request({
    url: `user/sms/login-name-and-mobile-verify-code?mobile=${mobile}`,
    method: "post"
  })
}

// 手机号验证码登录
export const axiosMobileLogin = (data) => {
  return axios.request({
    url: `user/login-mobile-verify-code`,
    method: "post",
    data: data
  })
}
// 手机号密码登录
export const axiosMobilePasswordLogin = (data) => {
  return axios.request({
    url: `user/login`,
    method: 'post',
    data: data
  })
}


// 手机号注册
export const axiosMobileRegister = (data) => {
  return axios.request({
    url: `user/register-mobile`,
    method: "post",
    data: data
  })
}

// 是否绑定微信
export const axiosGetIsBindWx = () => {
  return axios.request({
    url: `user/is-bind-wx`,
    method: "get"
  })
}

// 绑定微信
export const axiosBindWx = (data) => {
  return axios.request({
    url: `user/bind-wx`,
    method: "post",
    data
  })
}
// 新登录
export const wquserlogin = (data) => {
  return axios.request({
    url: `/wquser/login`,
    method: "post",
    data
  })
}

// 新登录
export const isLogon = () => {
  return axios.request({
    url: `/user/isLogon`,
    method: "get"
  })
}
// 新登录
export const getredisuserId = (userId) => {
  return axios.request({
    url: `/wquser/get-redis-userId/${userId}`,
    method: "get"
  })
}
// 绑定团购码
export const bindSpreadCode = (userId, spreadCode) => {
  return axios.request({
    url: `/wquser/user-bind-spreadCode/${userId}/${spreadCode}`,
    method: "get"
  })
}
// 查询代理商
export const getUserAgent = (userId) => {
  return axios.request({
    url: `/agent/get-user-agent/${userId}`,
    method: "get"
  })
}
// 查询代理商
export const getUsercode = (userId) => {
  return axios.request({
    url: `/shopping/get-user-code/${userId}`,
    method: "get"
  })
}
// 特殊优惠券绑定
export const buildspecial = (data) => {
  return axios.request({
    url: `/coupon/user-coupon-build-special`,
    method: "post",
    data: data
  })
}
/**
 * 授权，登录
 * @param {*} code 微信授权码
 * @returns
 */
export const getWxUserInfo = (userId, url) => {
  return axios.request({
    url: `/user/get-user-by-fx-weixingzhcode?userId=${userId}&url=${url}`,
    method: "get"
  })
}

// 手机号密码登录
export const userPasswordLogin = (data) => {
  return axios.request({
    url: `/user/login`,
    method: "post",
    data: data
  })
}

// 退出
export const wquserlogout = () => {
  return axios.request({
    url: `/user/logout`,
    method: "get"
  })
}


