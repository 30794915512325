import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import './assets/css/index.scss' // 公共样式
import config from '@/config/config.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';
import wx from 'weixin-js-sdk'
import SvgIcon from '@/components/svg-icon/index.vue' // 引入上面封装的svg component
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
dayjs.extend(relativeTime);
Vue.prototype.$dayJS = dayjs;

Vue.component('svg-icon', SvgIcon)
Vue.prototype.$wx = wx;

Vue.use(ElementUI);
Vue.prototype.$config = config;
Vue.config.productionTip = false

Vue.use(ElementUI)


Vue.prototype.$wx = wx
Vue.prototype.$config = config
axios.defaults.withCredentials = true
axios.defaults.baseURL = "/apis"
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
